.cfo-subtitle {
  color: $c-font-page;
  padding: 10px 20px 2px 20px;
  font-size: 1.3em;
  font-weight: bold;
}

.cfo-text {
  color: $c-font-page;
  font-size: 1.2em;
  margin: 10px 20px;
}

.dark {
  .cfo-subtitle {
    color: #999;
  }

  .cfo-text {
    color: #999;
  }
}

.iframeD {
  border: none;
  min-height: 2000px;
}

.coach-list__main {
  .box__top {
    padding: 0 !important;
  }
}
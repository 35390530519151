@function local-font($path) {
  @return url(#{$font-path}/#{$path});
}

/* Icon fonts */

@font-face {
  font-family: 'Noto Chess';
  font-display: block;
  src:
    local-font('lichess.chess.woff2') format('woff2'),
    local-font('lichess.chess.woff') format('woff');
}


/* manrope-300 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  //src: url('./manrope-v15-cyrillic_cyrillic-ext_latin_latin-ext-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  src:
    local('Manrope'),
    local-font('manrope/manrope-v15-cyrillic_cyrillic-ext_latin_latin-ext-300.woff2') format('woff2');
}
/* manrope-regular - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  // src: url('./manrope-v15-cyrillic_cyrillic-ext_latin_latin-ext-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  src:
    local('Manrope'),
    local-font('manrope/manrope-v15-cyrillic_cyrillic-ext_latin_latin-ext-regular.woff2') format('woff2');
}
/* manrope-500 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  // src: url('./manrope-v15-cyrillic_cyrillic-ext_latin_latin-ext-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  src:
    local('Manrope'),
    local-font('manrope/manrope-v15-cyrillic_cyrillic-ext_latin_latin-ext-500.woff2') format('woff2');
}
/* manrope-600 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  // src: url('./manrope-v15-cyrillic_cyrillic-ext_latin_latin-ext-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  src:
    local('Manrope'),
    local-font('manrope/manrope-v15-cyrillic_cyrillic-ext_latin_latin-ext-600.woff2') format('woff2');
}
/* manrope-700 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  // src: url('./manrope-v15-cyrillic_cyrillic-ext_latin_latin-ext-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  src:
    local('Manrope'),
    local-font('manrope/manrope-v15-cyrillic_cyrillic-ext_latin_latin-ext-700.woff2') format('woff2');
}
/* manrope-800 - cyrillic_cyrillic-ext_latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 800;
  // src: url('./manrope-v15-cyrillic_cyrillic-ext_latin_latin-ext-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  src:
    local('Manrope'),
    local-font('manrope/manrope-v15-cyrillic_cyrillic-ext_latin_latin-ext-800.woff2') format('woff2');
}



/* Noto Sans (v7) */

/* cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  src:
    local('Noto Sans'),
    local('NotoSans'),
    local-font('noto-sans-cyrillic-ext.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Noto Sans';
  src:
    local('Noto Sans'),
    local('NotoSans'),
    local-font('noto-sans-cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* devanagari */
@font-face {
  font-family: 'Noto Sans';
  src:
    local('Noto Sans'),
    local('NotoSans'),
    local-font('noto-sans-devanagari.woff2') format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839,
    U+A8E0-A8FB;
}

/* greek-ext */
@font-face {
  font-family: 'Noto Sans';
  src:
    local('Noto Sans'),
    local('NotoSans'),
    local-font('noto-sans-greek-ext.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Noto Sans';
  src:
    local('Noto Sans'),
    local('NotoSans'),
    local-font('noto-sans-greek.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Noto Sans';
  src:
    local('Noto Sans'),
    local('NotoSans'),
    local-font('noto-sans-vietnamese.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Noto Sans';
  src:
    local('Noto Sans'),
    local('NotoSans'),
    local-font('noto-sans-v14-latin-ext-regular.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
    U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Noto Sans';
  src:
    local('Noto Sans'),
    local('NotoSans'),
    local-font('noto-sans-v14-latin-regular.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Noto Sans Bold (v7)

/* cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-weight: 700;
  src:
    local('Noto Sans Bold'),
    local('NotoSans-Bold'),
    local-font('noto-sans-bold-cyrillic-ext.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Noto Sans';
  font-weight: 700;
  src:
    local('Noto Sans Bold'),
    local('NotoSans-Bold'),
    local-font('noto-sans-bold-cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* devanagari */
@font-face {
  font-family: 'Noto Sans';
  font-weight: 700;
  src:
    local('Noto Sans Bold'),
    local('NotoSans-Bold'),
    local-font('noto-sans-bold-devanagari.woff2') format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839,
    U+A8E0-A8FB;
}

/* greek-ext */
@font-face {
  font-family: 'Noto Sans';
  font-weight: 700;
  src:
    local('Noto Sans Bold'),
    local('NotoSans-Bold'),
    local-font('noto-sans-bold-greek-ext.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Noto Sans';
  font-weight: 700;
  src:
    local('Noto Sans Bold'),
    local('NotoSans-Bold'),
    local-font('noto-sans-bold-greek.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Noto Sans';
  font-weight: 700;
  src:
    local('Noto Sans Bold'),
    local('NotoSans-Bold'),
    local-font('noto-sans-bold-vietnamese.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Noto Sans';
  font-weight: 700;
  src:
    local('Noto Sans Bold'),
    local('NotoSans-Bold'),
    local-font('noto-sans-v14-latin-ext-700.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
    U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Noto Sans';
  font-weight: 700;
  src:
    local('Noto Sans Bold'),
    local('NotoSans-Bold'),
    local-font('noto-sans-v14-latin-700.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Roboto Light (v18) */

/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    local-font('roboto-light-cyrillic-ext.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    local-font('roboto-light-cyrillic.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    local-font('roboto-light-greek-ext.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    local-font('roboto-light-greek.woff2') format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    local-font('roboto-light-vietnamese.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    local-font('roboto-light-latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F,
    U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    local-font('roboto-light-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.button {
  @extend %roboto-fat, %box-radius;

  background: $c-primary;
  text-transform: uppercase;
  padding: 0.8em 1em;
  border: none;
  cursor: pointer;
  text-align: center;
  user-select: none;
  white-space: nowrap;
  display: inline-block;

  @include transition;

  &,
  &:visited,
  &:focus {
    color: $c-over;
  }

  &:not(.disabled):hover {
    @extend %button-raised-shadow;

    color: $c-over;
    background: $m-primary--lighten-7;
  }

  &:not(.disabled):focus-visible {
    @extend %focus-shadow;
  }

  &-no-upper {
    text-transform: none;
  }

  &-thin {
    padding: 0.1em 1em;
    font-size: 90%;
  }

  &-fat {
    font-size: 1.6rem;
  }

  &-green {
    background: $c-secondary;

    &:not(.disabled):hover {
      background: $m-secondary--lighten-4;
    }
  }

  &-red {
    background: $c-error;

    &:not(.disabled):hover {
      background: $m-bad--lighten-11;
    }
  }

  &-link {
    @extend %button-none;

    color: $c-link;

    &:hover,
    &:active,
    &:focus {
      color: $c-link-hover;

    }
  }

  &.button-empty {
    transition: none;

    &,
    &:hover {
      color: $c-primary;
    }

    background: none;
    box-shadow: none;

    &,
    &.button-green,
    &.button-red {
      &:not(.disabled):hover {
        @extend %metal;
      }
    }

    &.button-red,
    &.button-red:hover {
      color: $c-error;
    }

    &.button-green,
    &.button-green:hover {
      color: $c-secondary;
    }
  }

  &.button-metal {
    background: $c-primary;
    //text-shadow: 0 1px 0 $c-font-shadow;
    &,
    &:hover {
      color: white;
    }

    &:not(.disabled):hover {
      background: $c-secondary;
      text-shadow: 0 1px 0 $c-font-shadow;
    }
    margin: 15px;
  }

  &.button-dark-green {
    background: hsl(118deg 76.97% 42.27%);
    margin: 16px;
    &,
    &:hover {
      color: white;
    }

    &:not(.disabled):hover {
      background: hsl(209 90% 60%);
      text-shadow: 0 1px 0 $c-font-shadow;
    }
  }

  &.button-dark-green-bottom {
    background: hsl(118deg 76.97% 42.27%);
    margin: 16px;
    &,
    &:hover {
      color: white;
    }

    &:not(.disabled):hover {
      background: hsl(209 90% 60%);
      text-shadow: 0 1px 0 $c-font-shadow;
    }
    margin-top: 10px;
    margin-bottom: 100px;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.active {
    @extend %active;

    color: #fff;
  }

  &.active:not(.disabled):hover {
    @extend %active-hover;

    color: #fff;
  }
}
.button-set {
  @extend %flex-center;
  gap: 1.5em;
}
